import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Jumbo } from './Jumbo.js';
import { About } from './About.js';
import { Footer } from './Footer.js';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu />
        <Jumbo />
        <Container>
          {this.props.children}
        </Container>
        <About />
        <Footer />
      </div>
    );
  }
}
