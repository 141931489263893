import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import './Jumbo.css';

export class Jumbo extends Component {
    render() {
        return (
            <Jumbotron>
                <h1>Members Interests</h1>
                <br />
                <p>
                    We've indexed the Register of Members Financial Interests so you don't have to.
                </p>
            </Jumbotron>
        );
    }
}