import React, { Component } from 'react';
import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
} from 'reactstrap';
import moment from 'moment';

export class Search extends Component {
    static displayName = Search.name;

    constructor(props) {
        super(props);

        this.state = {
            input: '',
            hits: [],
            current: false,
            message: null
        }

        this.changeValue = this.onChange.bind(this);
        this.handleClick = this.onClick.bind(this);
        this.keyPressed = this.onKeyPress.bind(this);
        this.handleCurrentClick = this.onCurrentClick.bind(this);
    }

    onChange(e) {
        this.setState({ input: e.target.value });
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.search()
        }
    }

    onClick(e) {
        this.search()
    }

    onCurrentClick(e) {
        this.setState({ current: e.target.checked });
    }

    search() {
        let api = this.state.current ? `api/v1/search/current/${this.state.input}` : `api/v1/search/${this.state.input}`;

        fetch(api)
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                this.setState({
                    hits: data.hits,
                    message: <div class="font-italic">Found {data.total} results in {data.elapsedMilliseconds}ms</div>
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    hits: [],
                    message: <div class="font-italic">No results!</div>
                });
            });
    }

    render() {
        return (
        <React.Fragment>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <h4>Search Registered Interests</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <InputGroup>
                        <Input onChange={this.changeValue} onKeyPress={this.keyPressed} placeholder="Enter search term(s)" />
                        <InputGroupAddon addonType="append"><Button color="secondary" onClick={this.handleClick}>Search</Button></InputGroupAddon>
                    </InputGroup>
                </div>
                <div class="col-md-10 offset-md-1">
                    <div class="form-check auto-width">
                            <input type="checkbox" class="form-check-input" id="current" onClick={this.handleCurrentClick}/>
                        <label class="form-check-label" htmlFor="current">Restrict to current session</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-10 offset-md-1">
                    {this.state.message}
                </div>
            </div>

            {this.state.hits.map(hit => {
                return (
                    <div class="row">
                        <div class="col-md-10 offset-md-1">
                            <a href={hit.url} target="_blank" class="btn btn-link" rel="noopener noreferrer">{hit.linkTitle} ({hit.party}), {hit.constituency}</a>
                        </div>
                        <div class="col-md-8 offset-md-2 small font-italic">
                            <div>Registered {moment(hit.indexedOn).format("LL")}</div>
                        </div>
                        {hit.highlights.map(highlight => {
                            return (
                                <div class="col-md-8 offset-md-2" dangerouslySetInnerHTML={{ __html: highlight.text }}/>
                            )
                        })}
                    </div>
                )
            })}

            <div class="small-spacer"/>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <h5>The Register</h5>
                    <p>
                        The Register of Members' Financial Interests (RMFI) is published soon after the beginning of a new Parliament,
                        under the authority of the Committee on Standards and Privileges, and annually thereafter.
                        Between publications the Register is regularly updated electronically.
                    </p>
                    <p>
                        We update our index after each new publication.
                    </p>
                </div>
            </div>
            </React.Fragment>
        );
    }
}