import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//import spinner from './images/SpinningArrow.gif';

export class RegisterEntryList extends Component {
    static displayName = RegisterEntryList.name;

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.changeValue = this.onChange.bind(this);
        this.state = {
            hasData: this.props.entries.Count === 0 ? false: true,
            entries: this.props.entries,
            error: null,
            dropDownValue: 'Select Entry',
            dropdownOpen: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entries !== this.props.entries) {
            this.setState({
                entries: this.props.entries
            });
        }

        if (prevProps.dropDownValue !== this.props.dropDownValue) {
            this.setState({
                dropDownValue: this.props.dropDownValue
            });
        }
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    onChange(e) {
        this.setState({ dropDownValue: e.currentTarget.textContent });
        let id = e.currentTarget.getAttribute("id");
        let entry = this.state.entries.find(e => e.entryId === id);
        
        this.props.onChangeEntry(entry);
    }

    render() {
        const { error, hasData, entries, dropdownOpen } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!hasData) {
            return <div>No data</div>;
        } else {
            return (
                <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        {this.state.dropDownValue}
                    </DropdownToggle>
                    <DropdownMenu style={{ overflowY: 'scroll', maxHeight: 300 }}>
                        {entries.map(entry => {
                            return <DropdownItem id={entry.entryId} key={entry.entryId} onClick={this.changeValue}>{entry.member.fullName}</DropdownItem>
                        })}
                    </DropdownMenu>
                </Dropdown>
            );
        }
    }
}