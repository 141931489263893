import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from './images/BigBen.png'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" dark>
          <Container>
            <a class="navbar-brand" rel="home" tag={Link} title="Members Interests" href="#ß">
                <img class="logo" src={logo} alt="Big Ben"/>
            </a>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/">Search</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/commons/registers/latest">Current Register</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/commons/entries/new">New Entries</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/commons/downloads">Downloads</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-light" to="/api">API</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
