import React, { Component } from 'react';
import logo from './images/csv-icon.png'

export class Download extends Component {
    static displayName = Download.name;

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            message: ''
        };
    }

    componentDidMount() {
        let api = `api/v1/commons/registers/files`;

        fetch(api)
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                this.setState({
                    files: data,
                    message: data.length === 0 ? <div class="font-italic">No results!</div> : null
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    files: [],
                    message: <div class="font-italic">No results!</div>
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <h4>Download the Register</h4>
                        <p><i>{this.state.message}</i></p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        {this.state.files.map(file => {
                            return (
                                <div class="btn-group small-horizontal-spacer">
                                    <a href={file.url} class="btn btn-lg btn-info btn-attachment">
                                        <img alt={file.filename} src={logo} />&nbsp;
                                        <span>{file.filename}</span>
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}