import React, {Component} from 'react';
import { WordCloud } from './WordCloud';

export class RegisterEntryDetail extends Component {
    static displayName = RegisterEntryDetail.name;

    constructor(props) {
        super(props);

        this.state = {
            entry: this.props.entry
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entry !== this.props.entry) {
            this.setState({
                entry: this.props.entry
            });
        }
    }

    render() {
        let description = `${this.state.entry.member.fullName} (${this.state.entry.member.party}), ${this.state.entry.member.constituency}`;
        let link = <a href={this.state.entry.entryUrl}>{description}</a>;

        return (
            <React.Fragment>
                <div class="row">
                    <div class="col-md-10 offset-md-1 text-center">{link}</div>
                </div>
                <div class="small-spacer" />
                <div class="row">
                    <div class="col-md-10 offset-md-2">
                        <WordCloud id={this.state.entry.entryId}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}