import React, { Component } from 'react';
import moment from 'moment';

export class NewEntries extends Component {
    static displayName = NewEntries.name;

    constructor(props) {
        super(props);

        this.state = {
            entries: [],
            message: null
        };
    }

    componentDidMount() {
        let api = `api/v1/commons/registers/entries/new`;

        fetch(api)
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                else return response.json();
            })
            .then((data) => {
                this.setState({
                    entries: data,
                    message: data.length === 0 ? <div class="font-italic">No results!</div> : null
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    entries: [],
                    message: <div class="font-italic">No results!</div>
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <h4>New Entries</h4>
                        <p><i>{this.state.message}</i></p>
                    </div>
                </div>

                {this.state.entries.map(entry => {
                    let description = `${entry.member.fullName} (${entry.member.party}), ${entry.member.constituency}`;
                    
                    return (
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <a href={entry.entryUrl} target="_blank" class="btn btn-link" rel="noopener noreferrer">{description}</a>
                            </div>
                            <div class="col-md-8 offset-md-2 small font-italic">
                                <div>Registered {moment(entry.registerDate).format("LL")}</div>
                            </div>

                            {entry.registeredInterest.map(interest =>
                            {
                                console.log(interest);
                                return (
                                    <div class="col-md-8 offset-md-2">
                                        <p>{interest}</p>
                                    </div>
                                 )
                            })}
                        </div>
                     )
                })}
            </React.Fragment>
        );
    }
}