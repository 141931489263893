import React, { Component } from 'react';
import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
} from 'reactstrap';

export class PostcodeLookup extends Component {
    static displayName = PostcodeLookup.name;

    constructor(props) {
        super(props);

        this.state = {
            input: '',
            entries: this.props.entries,
            constituency: null,
            message: null
        }

        this.changeValue = this.onChange.bind(this);
        this.handleClick = this.onClick.bind(this);
        this.keyPressed = this.onKeyPress.bind(this);
    }

    onChange(e) {
        this.setState({ input: e.target.value });
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.search()
        }
    }

    onClick(e) {
        this.search()
    }

    search() {
        let api = `api/v1/search/postcode/${this.state.input}`;

        fetch(api)
            .then((response) => {
                if (!response.ok)
                    throw new Error(response.status);
                else
                    return response.text();
            })
            .then((data) => {
                this.setState({
                    constituency: data.replace(/['"]+/g, ''),
                    message: null
                });
            })
            .catch((error) => {
                console.log(error);

                this.setState({
                    constituency: '',
                    message: <div class="font-italic">No results!</div>
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entries !== this.props.entries) {
            this.setState({
                entries: this.props.entries
            });
        }

        if (prevState.constituency !== this.state.constituency) {
            let entry = this.state.entries.find(e => e.member.constituency === this.state.constituency);

            if (entry) {
                this.props.onChangeEntry(entry);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <InputGroup>
                    <Input onKeyPress={this.keyPressed} onChange={ this.changeValue } placeholder="Enter postcode" />
                    <InputGroupAddon addonType="append"><Button color="secondary" onClick={this.handleClick}>Search</Button></InputGroupAddon>
                </InputGroup>
                <div>{this.state.message}</div>
                <p>
                    Powered by <a href="https://mapit.mysociety.org/" class="btn btn-link" target="_blank" rel="noopener noreferrer">MapIt</a>
                </p>
            </React.Fragment>
        );
    }
}