import React, { Component } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { Resizable } from 're-resizable';

const resizeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 1px #444',
    background: '#444',
};

// https://react-wordcloud.netlify.com

export class WordCloud extends Component {
    static displayName = WordCloud.name;

    state = {
        words: [],
        isLoaded: true,
        error: ''
    }

    constructor(props) {
        super(props);

        this.getWordCount(this.props.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id
            });

            this.getWordCount(this.props.id);
        }
    }

    getWordCount(id) {
        fetch(`api/v1/commons/registers/wordcloud/${id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        words: result.wordCounts
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error: error
                    });
                }
            )
    }

    render() {
        return (
            <Resizable
                defaultSize={{
                    width: "85%",
                    height: 300,
                }}
                style={resizeStyle}>
                <div style={{ width: '100%', height: '100%' }}>
                    <ReactWordcloud options={{ enableTooltip: false, fontSizes: [24, 48] }} words={this.state.words} maxWords={40} />
                </div>
            </Resizable>
        );
    }
}
