import React, { Component } from 'react';
import email from './images/Email-white-26.png';
import twitter from './images/Twitter-white-26.png';
import facebook from './images/Facebook-white-26.png';
import mastadon from './images/Mastadon-white-26.png';

export class About extends Component {
    static displayName = About.name;

    render() {
        return (
            <React.Fragment>
                <div class="small-spacer" />
                <hr />
                <div class="row">
                    <div class="col-md-5 offset-md-1">
                        <h4>About Us</h4>
                        <p>
                            There are two of us: A husband and wife team who work as a Solution Architect and Project Manager respectively.
                            We are independent of any political party and are not funded by anyone other than ourselves.
                        </p>
                        <p>
                            If you would like to contribute towards the cost of running the site you can do so via PayPal.  Many Thanks!
                        </p>
                        <div class="center-block">
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="YHWX3ZGX2T7CW"/>
                                <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online."/>
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1"/>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <h4>Contact</h4>
                        <p>
                            <address>
                                <img src={email} alt="Email" />
                                <a href="mailto:hello@membersinterests.org.uk" class="btn btn-link">hello@membersinterests.org.uk</a>
                            </address>
                            <address>
                                <img src={twitter} alt="Twitter" />
                                <a href="https://twitter.com/MPInterests" class="btn btn-link" target="_blank" rel="noopener noreferrer">@MPInterests</a>
                            </address>
                            <address>
                                <img src={facebook} alt="Facebook" />
                                <a href="https://www.facebook.com/mpinterests" class="btn btn-link" target="_blank" rel="noopener noreferrer">MPInterests</a>
                            </address>
                            <address>
                                <img src={mastadon} alt="Mastadon" />
                                <a rel="me" href="https://newsie.social/@mpinterests" class="btn btn-link" target="_blank">Mastodon</a>
                            </address>
                        </p>
                        <p>
                            <i>Don't forget to tell everyone what you discover using <b>#mpinterests</b> and <b>#transparency</b> as  tags!</i>
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}