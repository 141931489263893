import React, { Component } from 'react';
import { RegisterEntryList } from './RegisterEntryList';
import { RegisterEntryDetail } from './RegisterEntryDetail';
import { PostcodeLookup } from './PostcodeLookup';

export class RegisterEntry extends Component {
    static displayName = RegisterEntry.name;

    constructor(props) {
        super(props);

        this.state = {
            entries: [],
            entry: {
                member: {
                    memberId: 0,
                    fullName: '',
                    constituency: '',
                    party: '',
                    house: ''
                },
                entryUrl: '#',
                wordCounts: []
            },
            dropDownValue: '',
            isLoaded: false,
            error: ''
        };
    }

    componentDidMount() {
        fetch("api/v1/commons/registers/entries/latest")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        entries: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        entries: [],
                        error
                    });
                }
        )
    }

    handleEntry = (entry) => {
        this.setState({ entry: entry });
    }

    handlePostcode = (entry) => {
        console.log(entry);

        this.setState({
            dropDownValue: entry.member.fullName,
            entry: entry
        });
    }

    render() {
        console.log(this.state.entry.member.memberId);
        const showEntryDetail = this.state.entry.member.memberId > 0;
        let entryDetail;

        if (showEntryDetail) {
            entryDetail = <RegisterEntryDetail entry={this.state.entry} />
        }

        return (
            <div>
                <div class="row">
                    <div class="col-md-5 offset-md-1">
                        <h4>Choose an MP from the list</h4>
                        <RegisterEntryList dropDownValue={this.state.dropDownValue} entries={this.state.entries} onChangeEntry={this.handleEntry}/>
                    </div>
                    <div class="col-md-5">
                        <h4>Search by postcode</h4>
                        <PostcodeLookup entries={this.state.entries} onChangeEntry={this.handlePostcode}/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        {entryDetail}
                    </div>
                </div>
            </div>
        )
    }
}