import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Search } from './components/Search';
import { RegisterEntry } from './components/RegisterEntry';
import { NewEntries } from './components/NewEntries';
import { Api } from './components/Api';
import { Download } from './components/Download';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Search} />
        <Route path='/commons/registers/latest' component={RegisterEntry} />
        <Route path='/commons/entries/new' component={NewEntries} />
        <Route path='/api' component={Api} />
        <Route path='/commons/downloads' component={Download} />
      </Layout>
    );
  }
}
