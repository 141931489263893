import React, { Component } from 'react';

export class Api extends Component {
    static displayName = Api.name;

  render () {
      return (
          <div>
              <div class="row">
                  <div class="col-md-7">
                      <h4 class="text-center">Developers can access our database using a REST API</h4>
                      <h5 class="small-spacer"><strong>Usage and Licensing</strong></h5>
                      <p>Please display the following attribution <strong>Data Service by MembersInterests</strong> linking back to this page.</p>
                      <p>Access to our API is provided free of charge, but we ask that you be considerate in your usage.</p>
                      <p class="small-spacer"><em>To contribute to the cost of running this site please use the link provided below.</em></p>
                  </div>
                  <div class="well light-bg col-md-5">
                      <h4 class="text-center">API Calls</h4>
                      <p>API Definition and json file</p>
                      <p><code>/swagger</code></p>
                      <p>Return a list of Registers</p>
                      <p><code>/api/v1/commons/registers</code></p>
                      <p>Return a list of Register Entries</p>
                      <p><code>/api/v1/commons/registers/&#123;registerId&#125;</code></p>
                      <p>Return a Register Entry</p>
                      <p><code>/api/v1/commons/registers/entries/&#123;entryId&#125;</code></p>
                      <p>Search our Index</p>
                      <p><code>/api/v1/search/&#123;searchTerm&#125;</code></p>
                      <p><code>/api/v1/search/current/&#123;searchTerm&#125;</code></p>
                  </div>
              </div>
          </div>
      );
  }
}